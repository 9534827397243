<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >

        <van-collapse v-model="activeNames">
          <div v-for="item in dataList" :key="item.productAttrUnique" :name="item.productAttrUnique">
            <van-collapse-item title="同类不同价格" :name="item.productId">
              <template #title>
                <van-card
                    :price="(Number(item.price) * 100 / 100).toFixed(2)"
                    :title="item.storeName"
                    :thumb="item.image"
                >
                  <template #desc>
                    <div >
                      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 200px;">
                        {{item.sku}}
                      </div>
                    </div>
                  </template>
                </van-card>
              </template>
              <div v-for="subItem in item.subItem" :key="subItem.productAttrUnique" :name="subItem.productId">
                <div role="button" tabindex="0"
                     class="van-cell van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
                  <van-icon name="cash-on-deliver" />
                  <div class="van-cell__value van-cell__value--alone van-contact-card__value">
                    <div>规格：{{ subItem.sku }}</div>
                    <div>价格：{{ subItem.price }}元</div>
                  </div>
                  <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon" @click="onShowPriceDetail(item.productId, subItem.sku, item.storeName)"></i>
                </div>
              </div>
            </van-collapse-item>
          </div>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="orderInfoPopupShow">
      <div v-for="item in orderInfos" :key="item.id" :name="item.id" style="width: 300px">
        <van-card
            :price="(Number(item.price) * 100 / 100).toFixed(2)"
            :desc="item.sku"
            :title="item.storeName"
            :thumb="item.image"
            :num="item.number"
        >
        </van-card>
      </div>
    </van-popup>

    <van-popup v-model="priceDetailPopupShow" position="bottom" :style="{ height: '30%',width: '100%'}">
      <van-notice-bar
          wrapable
          :scrollable="false"
          :text="popupShowProductName + '规格' + '【'+popupShowSku+'】' + '历史价格'"
      />
      <van-steps direction="vertical">
        <van-step v-for="item in priceDetails" :key="item.price" :name="item.price">
          <div>【{{popupShowSku}}】{{item.price}}元 {{item.orderTime}}</div>
        </van-step>
      </van-steps>
    </van-popup>
  </div>


</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Form,
  Button,
  Field,
  Uploader,
  Popup,
  ContactCard,
  Tag,
  Image as VanImage,
  Card,
  Stepper,
  SubmitBar,
  Divider,
  Checkbox,
  DatetimePicker,
  Sticky,
  Tab,
  Tabs,
  Sidebar,
  SidebarItem, Loading, PullRefresh, ImagePreview, List, CheckboxGroup, Image, Notify, Empty, Dialog
  , Collapse, CollapseItem, NoticeBar, Step, Steps
} from 'vant';

import * as adminPriceService from "@/api/admin/price";
import * as userAdminService from "@/api/admin/user";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    [Card.name]: Card,
    [Stepper.name]: Stepper,
    [SubmitBar.name]: SubmitBar,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [DatetimePicker.name]: DatetimePicker,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [NoticeBar.name]: NoticeBar,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  name: "PriceList",
  props: ["searchParam"],
  data() {
    return {
      ddd: false,
      activeNames: [],
      orderInfoPopupShow: false,
      priceDetailPopupShow: false,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      orderInfos: [],
      priceDetails: [],
      popupShowSku: null,
      popupShowProductName: null,
    };
  },
  methods: {
    onShowPriceDetail(productId, sku, productName) {
      this.popupShowSku = sku
      this.priceDetailPopupShow = true
      this.popupShowProductName = productName
      let param = {
        userId: this.searchParam.userId
      }
      adminPriceService.getPriceDetail(productId, sku, param).then(res => {
        if (res.status === 200) {
          this.priceDetails = res.data
        }
      })
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      console.log(this.searchParam)
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminPriceService.getUserPrice(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }

          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      console.log(this.searchParam)
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
  },
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
