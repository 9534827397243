<template>
  <div>
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div>
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">分类</div>
        </template>
      </van-search>
    </div>

    <PriceList :searchParam="searchParam" ref="PriceList"></PriceList>
    <itemSelectPopup ref="itemSelectPopup"></itemSelectPopup>
  </div>
</template>

<script>
import {
  Button,
  Cell, NavBar, Popup, Search, ShareSheet, Icon
} from 'vant';
import PriceList from "@/view/price/list";
import {mapState} from "vuex";
import itemSelectPopup from "@/components/itemSelectPopup";

export default {
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    PriceList,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    [Icon.name]: Icon,
    itemSelectPopup: itemSelectPopup,
  },

  data() {
    return {
      options: [],
      title: '商品通用价格',
      searchParamValue: '',
      searchParam: {},
    };
  },
  watch: {
    cate: {
      handler(newVal) {
        if (newVal.cateId === undefined) {
          return
        }
        this.onSelectCate(newVal.cateId)
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      cate: state => state.order.cate,
    }),
  },
  created() {
    if (this.$route.params && this.$route.params.userId) {
      this.searchParam.userId = this.$route.params.userId;
    }
    this.searchParam.orderType = 2;
  },
  methods: {
    toHome() {
      this.$router.push({path: '/index'})
    },
    onSelectCate(cateId) {
      this.searchParamValue = '条件筛选'
      this.searchParam.cateId = cateId
      this.$refs.PriceList.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.storeName = null
      this.searchParam.cateId = null
      this.$refs.PriceList.onRefresh();
    },
    onSearch() {
      this.searchParam.storeName = this.searchParamValue;
      this.$refs.PriceList.onRefresh();
    },
    showPopup() {
      this.$refs.itemSelectPopup.isPopupShow = true
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
