import request from '@/utils/admin/request'

export function getAccountUserKey(params) {
    return request({
        url: '/charge-account/user/key',
        method: 'get',
        params: params
    });
}

export function getAccountUserKeyList(params) {
    return request({
        url: '/charge-account/user/get-user-key-list',
        method: 'get',
        params: params
    });
}

export function getAccountUser(userId) {
    return request({
        url: '/charge-account/user/' + userId,
        method: 'get'
    });
}

export function getAnalysis(userId) {
    return request({
        url: '/charge-account/user/analysis/' + userId,
        method: 'get'
    });
}

export function editUser(data) {
    return request({
        url: '/charge-account/user',
        method: 'put',
        data: data
    });
}

export function deleteUser(id) {
    return request({
        url: '/charge-account/user/' + id,
        method: 'delete',
    });
}


