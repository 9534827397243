<template>
  <van-popup
      v-model="isPopupShow"
      position="bottom"
      :style="{ height: '40%' }"
  >
    <van-grid :column-num="4">
      <van-grid-item v-for="item in options" :text="item.cateName" :key="item.id" icon="photo-o"
                     @click="onSelectCate(item)">
        <van-image
            :src="item.pic"
        />
      </van-grid-item>
    </van-grid>
  </van-popup>
</template>

<script>
import {ShareSheet, Card, ContactList, Grid, GridItem} from 'vant';
import * as priceAdminService from "@/api/admin/price";
import $store from "@/store";

export default {
  components: {
    [Card.name]: Card,
    [ContactList.name]: ContactList,
    [ShareSheet.name]: ShareSheet,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data() {
    return {
      isPopupShow: false,
      options: []
    };
  },
  created() {
    priceAdminService.getItems().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    })
  },
  methods: {
    onSelectCate(item) {
      let obj = {
        cateId: item.id,
        cateName: item.cateName,
        cateImage: item.pic
      }
      $store.commit('SET_CATE', obj)
      this.isPopupShow = false
    },
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
