import request from '@/utils/admin/request'

export function getUserPrice(param) {
    return request({
        url: '/charge-price',
        method: 'get',
        params: param
    });
}

export function getItems() {
    return request({
        url: '/charge-price/items',
        method: 'get',
    });
}

export function getWholesaleProductStock(param) {
    return request({
        url: '/wholesale-product-stock',
        method: 'get',
        params: param
    });
}

export function getWholesaleProductStockByProduct(productId, param) {
    return request({
        url: '/wholesale-product-stock/' + productId,
        method: 'get',
        params: param
    });
}

export function updateStock(data) {
    return request({
        url: '/wholesale-product-stock/update-stock',
        method: 'post',
        data: data
    });
}

export function coverStock(data) {
    return request({
        url: '/wholesale-product-stock/cover-stock',
        method: 'post',
        data: data
    });
}

export function getUnitData() {
    return request({
        url: '/charge-account/common/unit-data',
        method: 'get',
    });
}

export function getPriceDetail(productId, sku, param) {
    return request({
        url: '/charge-price/' + productId + '/' + sku,
        method: 'get',
        params: param
    });
}

export function getSaleLine(beforeMonth) {
    return request({
        url: '/charge-analysis/sale-line/' + beforeMonth,
        method: 'get',
    });
}

export function getCreditColumn() {
    return request({
        url: '/charge-analysis/credit-column',
        method: 'get',
    });
}

export function getCreditAmount() {
    return request({
        url: '/charge-analysis/credit-amount',
        method: 'get',
    });
}

export function getSaleLineMonth(beforeMonth) {
    return request({
        url: '/charge-analysis/sale-line/year/' + beforeMonth,
        method: 'get',
    });
}

export function saleLineColumnAreaMonth() {
    return request({
        url: '/charge-analysis/sale-line-column-area/month',
        method: 'get',
    });
}

